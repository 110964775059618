/** Class representing a Calculator.
 * Handles validating fields and submission
 * */
class Calculator {
  constructor(form, calculatorInputs, stringElementNames, floatElementNames) {
    this.form = form;
    this.pages = document.querySelectorAll('[class^="calculator-page-"]');
    this.calculatorFields = calculatorInputs;
    this.calculatorInputs = Object.values(calculatorInputs);
    this.fieldValues = {};
    this.stringElementNames = stringElementNames;
    this.floatElementNames = floatElementNames;
    this.currentPage = 0;
    this.currentPageElement = null;
    this.currentForwardButton = null;
    this.currentForwardButtonMessage = null;
    this.pageValues = {};
    this.roiOutputMin = null; // should be set in child class
    this.roiOutPutMax = null; // should be set in child class
    this.pageLoaded = new Event('pageLoaded');
    this.init();
  }

	/**
	 * Adds some event listeners to the form and the back and forward buttons.
	 * @summary Adds some event listeners to the form and the back and forward buttons.
	 * @description Adds some event listeners to the form and the back and forward buttons. Actions non calculator validation running and page navigating.
	 * @returns {void}
   */
  init() {
    this.form.addEventListener('submit', this.onSubmit.bind(this));
    const backButtons = document.querySelectorAll('.calculator .back');

    backButtons.forEach(function (button) {
      button.addEventListener('click', this.goToPage.bind(this, -1));
    }, this);

    const forwardButtons = document.querySelectorAll('.calculator .forward');

    forwardButtons.forEach(function (button) {

      button.addEventListener('click', function (e) {
        const validationResults = this.validateNonCalculatorPage();

        if (validationResults === true) {
          this.goToPage(99);
        } else {
          // this.displayErrorMessage();
        }
      }.bind(this));
    }, this);
  }

	/**
	 * Validate the fields in the calculator.
	 * @summary Validate the fields in the calculator.
	 * @description Validate the fields in the calculator.
	 * @returns {array}
   */
  validateFields() {
    let invalidFields = [];

    this.calculatorInputs.forEach((field) => {
      const valid = this.validateField(field);

      if (valid !== true) {
        invalidFields.push(field);
      }
    });

    return invalidFields;
  }

	/**
	 * Validate a single field in the calculator.
	 * @summary Validate a single field in the calculator.
	 * @description Validate a single field in the calculator.
	 * @param {object} field - The field to validate.
	 * @returns {boolean|object} Will return the field if it is invalid or true if it is valid.
   */
  validateField(field) {
    const value = field.value;

    if (!value) {
      return field;
    }

    return true;
  }

	/**
	 * Sets the this.fieldValues object with the values from the calculator.
	 * @summary Sets the this.fieldValues object with the values from the calculator.
	 * @description Sets the this.fieldValues object with the values from the calculator. Will be used in the child classes.
	 * @returns {void}
   */
  setFieldValues() {
    this.calculatorInputs.forEach((field) => {
      let name = field.getAttribute('name').replace(/-/g, '_');
      let value = field.value;

      if (this.stringElementNames.includes(name)) {
        value = parseFloat(field.value);

      } else if (!this.floatElementNames.includes(name)) {
        value = parseInt(field.value);
      }

      this.fieldValues[name] = value;
    });
  }

	/**
	 * Removes validation error classes from the calculator.
	 * @summary Removes validation error classes from the calculator.
	 * @description Removes validation error classes from the voidator.
	 * @param {array} inputs - The calculator inputs
	 * @returns {void}
   */
  removeErrors(inputs) {
    inputs.forEach((input) => {
      input.classList.remove('error');
    });
  }

	/**
	 * Handles the submission of the calculator by validating and starting calculation run in child classes.
	 * @summary Validate the fields in the calculator.
	 * @description Validate the fields in the calculator.
	 * @param {object} event - The event object
	 * @returns {array}
   */
  onSubmit(event) {
    event.preventDefault();
    this.setFieldValues();

    const invalidInputs = this.validateFields();

    if (invalidInputs.length > 0) {

      invalidInputs.forEach((input) => {
        input.classList.add('error');
      });

    } else {
      this.removeErrors(this.calculatorInputs);
      const nextPage = this.currentPage + 1;
      this.pageValues[nextPage] = this.runCalculations();
      this.goToPage(nextPage);
    }
  };

  /**
   * Displays the page with the class `class="calculator-page-1"` if you send in `1` and hides all other pages.
   * Send in `-1` to go backwards one page. Send in `99` to go forward one page
   * @param {number} indexValue - The number found after the `calculator-page-` part of the class.
   */
  goToPage(indexValue) {

    if (indexValue === -1) {
      const newPageValue = this.currentPage - 1;
      indexValue = newPageValue;
      this.currentPage = newPageValue;

    } else if (indexValue === 99) {
      const newPageValue = this.currentPage + 1;
      indexValue = newPageValue;
      this.currentPage = newPageValue;

    } else {
      this.currentPage = indexValue;
    }

    if (indexValue == 3) {
      const mailAnim = document.getElementById("mail-animation");
      if (mailAnim) {
        const mailAnimSrc = mailAnim.dataset.src;
        mailAnim.load(mailAnimSrc);
      }
    }

    this.resetForwardButtonText();

    [...this.pages].map(function (pageElement, index) {

      if (index !== indexValue) {
        this.hidePage(pageElement);

      } else {
        this.showPage(pageElement);
        this.currentPageElement = pageElement;
        this.currentForwardButton = this.currentPageElement.querySelector('.forward');
        pageElement.dispatchEvent(this.pageLoaded);
        this.setForwardButtonText();
      }
    }, this);
  }

  showPage(pageElement) {
    pageElement.classList.add('show');
    pageElement.classList.remove('hide');
  }

  hidePage(pageElement) {
    pageElement.classList.add('hide');
    pageElement.classList.remove('show');
  }

  setForwardButtonText() {
    if(this.currentForwardButton === null){
      return;
    }

    const originalForwardButtonText = this.currentForwardButton.getAttribute('original-forward-text');

    if (originalForwardButtonText === '' || originalForwardButtonText === null) {
      this.currentForwardButton.setAttribute('original-forward-text', this.currentForwardButton.value);
      this.currentForwardButtonMessage = this.currentForwardButton.value;
    }
    this.currentForwardButton.value =
      this.currentForwardButtonMessage === null
        ? originalForwardButtonText
        : this.currentForwardButtonMessage;
  }

  resetForwardButtonText() {
    this.currentForwardButtonMessage = null;
    const originalForwardButtonText =
      this.currentForwardButton !== null
        ? this.currentForwardButton.getAttribute('original-forward-text')
        : null;

    if (originalForwardButtonText !== '' && originalForwardButtonText !== null) {
      this.currentForwardButton.value = originalForwardButtonText;
    }
  }

  runCalculations(arg) {
    /*should be implemented in child class*/
  }

  validateNonCalculatorPage(pageNumber) {
    /*should be implemented in child class*/
  }
}
